import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "warning-message" }
const _hoisted_2 = { class: "inline-flex100" }
const _hoisted_3 = { class: "message-content" }
const _hoisted_4 = { class: "top-text" }
const _hoisted_5 = ["index"]
const _hoisted_6 = { class: "bottom-text" }
const _hoisted_7 = {
  key: 0,
  class: "margin-right-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("span", { class: "font-icon" }, [
          _createElementVNode("i", {
            class: "fa fa-exclamation-circle",
            "aria-hidden": "true"
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.localValidationSummaryHeading), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorFields, (error, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: error,
            index: index,
            class: "inline-flex"
          }, [
            _createElementVNode("label", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(error), 1),
              (_ctx.errorFields.length-1!==index)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, ";"))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_5))
        }), 128))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("a", {
          href: "javascript:void(0)",
          "aria-hidden": "true",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Close();})
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("i", {
            class: "fa fa-times times-color",
            "aria-hidden": "true"
          }, null, -1)
        ]))
      ])
    ])
  ]))
}